import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';

import BreadcrumbMenu from '../../../components/menus/bcmenu';
import Layout from '../../../components/layout';
import React from 'react';
import Releases from '../../../components/releases/releases';
import SEO from '../../../components/seo';
import SideNavBar from '../../../components/menus/navbarmenu';
import { graphql } from 'gatsby';

let gmdv = require(`../../../ghc_config`);

const Detail = ({ data, location }) => {

  //  Menu relation
  let menuName = gmdv.getMenuid(`/about-us/press/press-releases`)

  //  Page
  const ReleasesList = data.allNodePressReleases
  const title = `Press Releases`
  return (
    <Layout>
      <SEO 
        title={`${title} | Genesis`} 
        description={`Genesis HealthCare offers long term care services, short stay care, transitional care services, rehab services and specialty services`}
        keywords={[`Long Term Care Services`, `Transitional Care`, `Transitional Care Services`, `Genesis Rehab`]} 
      />
      <main>
        <MDBContainer tag="section" id="maincontent">
          <MDBRow center>
            <MDBCol xs="12" md="3" lg="3" className="col-12 leftside">
              <SideNavBar menuName={menuName} location={location}/>
            </MDBCol>
            <MDBCol xs="12" md="9" lg="9" className="col-12 contentRight">
              <h1 className="pagetitle" 
                style={{backgroundImage: `url(${'/images/banners/Press_release_and_press_kit.png'})`}}>
                {title}
              </h1>
              <BreadcrumbMenu menuName={menuName} location={location}/>
              <Releases ReleasesList={ReleasesList} name={title}/>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </main>
    </Layout>
  )
}

export default Detail;

export const query = graphql`
query allNodePressReleasesList {
  allNodePressReleases(sort: {fields: field_happening_s_date, order: DESC}) {
    edges{
      node {
        field_happening_s_date
        field_link_to_the_page
        title
        body {
          value
        }
        path {
          alias
        }
      }
    }
  }

  # Menu relation
  allMenuLinkContentMenuLinkContent {
    edges {
      node {
        title
        link {
          uri
        }
        menu_name
      }
    }
  }
}
`